<template>
  <div style="padding: 20px 10px">
    <div class="name">智能租床租赁服务用户协议</div>
    <p class="paragraph">这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议。</p>
    <div class="title">1.这是用户协议</div>
    <p class="paragraph">1)这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议。</p>
    <p class="paragraph">2)这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议这是用户协议。</p>
  </div>
</template>

<script>
export default {
name: "Protocol"
}
</script>

<style scoped lang="scss">
  .name{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom:10px;
  }
  .paragraph{
    text-indent: 2em;
    font-size: 15px;
    color:#515151;
  }
  .title{
    font-size: 16px;
    font-weight: 700;
  }
</style>
